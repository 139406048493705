/* General Css */

.pt-page label {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;

  line-height: 17px;
  color: black;
}

.pt-page .dc-id-next button {
  background: #b5893f;
  margin-bottom: 0.5rem;
}

/* Patient Intro page */

.pt-splide-intro .splide__arrow svg {
  fill: #b5893f !important;
  width: 30px;
}

.pt-splide-intro .splide__pagination__page.is-active {
  background: #fff;
}

.pt-curved-intro {
  background-image: url(../images/bg/patientImg/intro-page1.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  width: 100%;
  height: fit-content;
  padding-top: 21%;
  position: absolute;
  bottom: 0;
  /* left: 0; */
}

.pt-curved-intro-2 {
  background-image: url(../images/bg/patientImg/intro-page2.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  height: fit-content;
  width: 100vw;
  padding-top: 25%;
  padding-bottom: 10px;
  position: absolute;
  bottom: 0;
}

.pt-curved-intro-3 {
  background-image: url(../images/bg/patientImg/intro-page3.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  height: fit-content;
  width: 100vw;
  padding-top: 10%;
  padding-bottom: 10px;
  position: fixed;
  bottom: 0;
}

.pt-curved-intro-4 {
  background-image: url(../images/bg/patientImg/intro-page4.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  height: fit-content;
  width: 100vw;
  padding-top: 20%;
  padding-bottom: 10px;
  position: fixed;
  bottom: 0;
}

.pt-curved-contact {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* top: 75%; */
  background-color: #e7d184;
  padding-bottom: 20px;
  margin-bottom: 0;
  color: black;
}

.pt-button-intro {
  background: #ad9d63;
  border: 0.888889px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
}

.pt-contact-row {
  color: #b5893f;
}

/* Registration Page */

.pt-reg-bot-row {
  background: #e7d184;
  position: fixed;
  bottom: 0;
  z-index: 10;
  margin-right: 0 !important;
  margin-left: 0 !important;
  background: url('../images/bg/patientImg/intro-page1.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: fit-content;
  padding-top: 22%;
  color: white;
}

.pt-register-title {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;

  line-height: 24px;
  color: black;
  text-decoration: none;
  margin-bottom: 30px !important;
}

.pt-register-input {
  color: black;
}

.pt-register-input label {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}

/* OTP Page */

.pt-otp-input {
  color: black;
}

.pt-disclaimer-consent-modal-top-body {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;

  line-height: 120%;
  color: black;
}

.pt-disclaimer-consent-modal-top-body span {
  color: #b5893f !important;
}

.pt-disclaimer-consent-modal-bot-body span {
  color: #b5893f !important;
}

.pt-disclaimer-consent-modal .modal-footer button {
  background: #b5893f;
}

.noti-popout-button {
  width: 100% !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  font-weight: bold !important;
}

/* First Time Register */

/* Doctor Info */

.pt-form-select {
  border: 1px solid #b5893f !important;
  color: #b5893f !important;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold !important;
}

.pt-form-select option {
  color: #b5893f !important;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold !important;
}

.pt-ftr-doctor-info {
  background: #b5893f;
  border-radius: 15px;
  color: white;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 13px 10px;
  padding-bottom: 20px;
  padding-right: 8px;
}

.pt-ftr-doctor-img {
  border-radius: 15px;
  object-fit: cover;
}

.pt-ftr-doctor-img img {
  width: 100px;
  height: 100px;
  border-radius: 15px;
  object-fit: cover;
}

.pt-ftr-doctor-info-text {
  margin-left: 10px;
}

.pt-ftr-doctor-info-title {
  color: white;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;

  line-height: 17px;
  margin-bottom: 5px !important;
}

.pt-ftr-doctor-info-body {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;

  line-height: 15px;
}

.pt-optional-container {
  background: #e7d184;
  border-radius: 12px;
}

.pt-optional-container .accordion-button {
  color: black !important;
  background: #e7d184 !important;
}

.pt-optional-container .accordion-body {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.pt-optional-reference {
  padding: 0 !important;
  color: #0d6efd;
  text-decoration: underline;
}

.or-label {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
}

.pt-thankyou {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  /* line-height: 165.5%; */
}

/* FTJ Page */

.pt-box-ftj {
  background: rgba(181, 137, 63, 0.2);
  border-radius: 15px;
  padding: 13px;
  margin-left: 5%;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 15px;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  line-height: 1.2rem;
  color: black;
}

.pt-box-ftj p:nth-of-type(1) {
  margin-bottom: 10px;
}

.pt-tutorial-div {
  margin-bottom: 45px;
}

.pt-tutorial-div label {
  margin-bottom: 10px;
}

.pt-tutorial-div img {
  width: 100%;
}

.pt-med-parts {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;

  line-height: 17px;
  color: black;
  margin-bottom: 20px;
}

.pt-fixed-input {
  height: 34.86px;
  width: 160px;
  justify-self: flex-end;
  align-self: flex-end;
}

.pt-form-row-first-col {
  margin-right: 5px;
}

.pt-form-row-input label {
  margin-bottom: 8px;
}

.pt-checkbox-box {
  background: #d5bf99;
  border-radius: 29px;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.pt-add-comment-row {
  color: #b5893f !important;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
}

.pt-family-checklist {
  display: flex;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9em;
  font-weight: bold;
  text-align: justify;
  line-height: 15px;
  margin-bottom: 5%;
  margin-left: 3%;
}

.pt-family-checklist input {
  border: 2px solid #000000;
  border-radius: 0.125rem;
}

.pt-curved-button-4 button {
  background: rgba(0, 0, 0, 0.25);
  border: 0.888889px solid #ffffff;
}

.patient-intro-button-4 {
  background: rgba(0, 0, 0, 0.25);
  border: 0.888889px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 7px 15px;
  color: white;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 13px;
}

@media only screen and (min-height: 600px) {
  .pt-curved-button-4 button {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .dc-top-img-intro2 img {
    width: 300px;
  }

  .intro-main-logo6 {
    width: 250px;
    margin-top: -20px;
  }

  .pt-curved-intro-2 {
    padding-bottom: 50px;
  }

  .dc-top-img-intro-4 img {
    width: 250px;
  }

  .pt-curved-intro-4 {
    padding-top: 33%;
  }
}

@media only screen and (min-height: 700px) {
  .pt-curved-button-4 button {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .dc-font-intro {
    margin-top: 2%;
  }

  .dc-top-img-intro2 img {
    width: 320px;
  }

  .pt-curved-intro-2 {
    padding-bottom: 50px;
  }

  .dc-top-img-intro-4 img {
    width: 255px;
  }
}

@media only screen and (min-height: 800px) {
  .pt-curved-button-4 button {
    margin-top: 5%;
    margin-bottom: 10%;
  }

  .pt-curved-intro-3 {
    padding-bottom: 30px;
  }

  .dc-top-img-intro2 img {
    width: 400px;
  }

  .pt-curved-intro-2 {
    padding-bottom: 55px;
  }

  .dc-top-img-intro-3 img {
    width: 500px !important;
  }

  .dc-font-intro {
    margin-top: 5%;
    margin-bottom: 2%;
  }

  .dc-bot-img-intro img {
    width: 260px;
  }

  .dc-top-img-intro-4 img {
    width: 300px;
  }

  .intro-main-logo6 {
    width: 320px;
    margin-top: -20px;
  }
}

.pt-box-policy-main {
  background: #d5bf99 !important;
}

.hide-arrows-pagination .splide__arrows,
.hide-arrows-pagination .splide__pagination {
  display: none;
}

.main-navigation2 {
  display: flex;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 25px; */
}

.main-intro-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-intro-body p {
  margin: 0;
  margin-top: 10px;
  line-height: 0.92em;
  font-size: 1rem;
  text-align: center;
}

.dc-patient-appointment-top-date {
  background: #d5bf9a;
  border-radius: 5px;
  padding: 5px 15px;
  color: #b5893f;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  margin-right: 15px;
}

.dc-patient-appointment-top-date p:nth-of-type(1) {
}

.dc-patient-appointment-top-date p:nth-of-type(2) {
}

.patient-text-center-heading-blue {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;

  color: #b5893f;
}

.dc-patient-appointment-top {
  background: #ffffff;
  border: 1px solid #b5893f;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 8px 15px;
  display: flex;
}

.booking-date-box {
  margin-left: 1rem;
  width: 12rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  box-shadow: 0px 3px 4px 1px #969696;
  height: fit-content;
}

.appointment-title {
  z-index: 1;
  margin-left: 10%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: #b5893f !important;
  color: #fff !important;
  width: 80%;
  border-radius: 10px;
}

.appointment-titles {
  z-index: 1;
  /* margin-top: 1rem; */
  margin-bottom: 0.5rem;
  background: #b5893f !important;
  color: #fff !important;
  width: 100% !important;
  border-radius: 10px;
}

.appointment-next-btn {
  /* position: relative;
  bottom: 0.5rem;
  z-index: 1;
  margin-top: 0.8rem;
  border-radius: 10px; */
}

.pt-info-box {
  width: 90%;
  margin-left: 1rem;
  margin-top: 0;
  font-size: 0.8em;
  margin-bottom: 20px;
}

.pt-addfam-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.pt-addfam-label h2 {
  font-weight: bold;
  color: #b5893f;
  /* text-decoration: underline; */
}

.pt-top-wave img {
  width: 100%;
}

.pt-title-notification-text {
  text-align: center;
  margin-top: 15px;
  font-weight: bold;
}

.user-prof-font input {
  font-weight: bold !important;
  font-size: 1.15em;
}

.user-prof-font,
.user-prof-font label {
  font-size: 1.15em;
}

.userprof-close-fam {
  width: 100%;
  color: #b5893f;
  font-size: 0.9em;
  padding-left: 2rem;
  margin-top: 3px;
  margin-bottom: 20px;
}

.userprof-close-fam p {
  margin: 0;
}

.close-fam-member {
  background: rgba(181, 137, 63, 0.37);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: none;
  display: flex;
  padding: 10px 20px;
}

.close-fam-member p {
  margin: 0;
}

.close-fam-member-img img {
  width: 40px;
  margin-right: 12px;
}

.close-fam-member-name {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.pt-close-fam-add-form input {
  border-radius: 15px;
}

.pt-close-fam-add-form input:focus {
  border: 2px solid #b5893f !important;
  box-shadow: inset 0px 0px 0px 1px #b5893f;
}

.pt-close-fam-form-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.pt-close-fam-form-button button {
  border: none;
  background: #b5893f;
  border-radius: 15px;
  color: white;
  padding: 5px 45px;
  font-size: 1.3em;
  margin-bottom: 20px;
}

.pt-submit-fam-input {
  width: 100%;
}

.pt-close-fam-save-delete {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.pt-close-fam-save-delete .col {
  display: grid;
}

.css-1480iag-MuiInputBase-root-MuiInput-root {
  font-size: inherit !important;
}

.pt-close-fam-save-delete button {
  background: #b5893f;
  border-radius: 15px;
  border: none;
  color: white;
  padding: 10px;
  width: 100%;
}

.pt-close-fam-edit-fam-member {
  width: 100%;
}

.btn-danger {
  color: #fff !important;
  background-color: #f93154 !important;
}

.btn-secondary {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.pt-notes .accordion-button {
  transition-duration: 2s !important;
}

.pt-notes .accordion-button .pt-dietitian-appointment-top-date {
  background: #fff;
}

.pt-notes .accordion-button .pt-dietitian-appointment-top-date .pt-text-color {
  color: #b5893f;
}

.pt-notes .accordion-button .dc-dietitian-appointment-top {
  background: #b5893f;
}

.pt-notes .accordion-button .pt-text-color-top,
.pt-notes .accordion-button .pt-text-color-bot {
  color: white;
}

.pt-notes .accordion-button:not(.collapsed) .pt-dietitian-appointment-top-date {
  background: rgba(213, 191, 154, 0.48) !important;
  transition: ease-in;
}

.pt-notes .accordion-button:not(.collapsed) .dc-dietitian-appointment-top {
  background: #fff;
}

.pt-notes .accordion-button:not(.collapsed) .pt-text-color-top {
  color: #000;
}

.pt-notes .accordion-button:not(.collapsed) .pt-text-color-bot {
  color: #b5893f;
}

.pt-notes .accordion-button .pt-notes-info {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pt-notes .accordion-button .pt-notes-info img {
  width: 30px;
}

.pt-notes .accordion-button::after {
  display: none !important;
}

.pt-notes .accordion-button {
  padding: 0 !important;
  box-shadow: none !important;
}

/* Modules Css */

.pt-mod-progress-bars {
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: center;
  align-self: center;
  height: fit-content;
  width: 100%;
}

.pt-mod-progress-bar {
  background: #c4c4c4;
  border: none;
  width: 20%;
  padding-bottom: 7px;
  margin-right: 4px;
}

.pt-mod-title {
  font-weight: bold;
  font-family: Verdana;
  font-style: normal;
  line-height: 22px;
  font-size: 0.8rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  padding-bottom: 1.2rem;
}

.pt-box-mod-3 {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 100%;
  padding: 0.9rem;
}

.pt-quest-title p {
  margin: 0;
  font-weight: bold;
  font-size: 0.9em;
}

.pt-quest-title select {
  border: 1px solid #1b1813;
  box-sizing: border-box;
  border-radius: 15px;
  padding-left: 18px;
  padding-right: 18px;
}

.pt-quest-desc {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  font-size: 0.9em;
  color: #000;
}

.form-check-no-marg-top input[type='checkbox'] {
  margin-top: 0 !important;
  border-radius: 5px !important;
}

.pt-mod-button-submit {
  border: none;
  border-radius: 15px;
  padding: 8px;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  text-align: center;
}

/* module 4 styling */

.true-false-btn {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 130px;
  height: 35px;
  padding-left: 1.5rem;
  padding-top: 0.3rem;
  margin-top: 0.3rem;
}

.next-question-btn {
  padding: 0.4rem;
  border-radius: 1rem;
  color: #fff;
  text-align: center;
}

.box-show-m4 {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.module-title-text {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-left: 1rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.title-box-shadow {
  box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 0.5rem;
  font-size: 0.9rem;
}
.mt-reflection-title {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 45%, #000000 100%);
  height: 7rem;
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
}

.mt-reflection-title-m4 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 45%, #000000 100%);
  height: 7rem;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.patient-main-btn {
  border-radius: 5px;
  border: none;
  height: 2.6rem;
  color: #fff;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  background-color: #b5893f;
  width: 100%;
  font-size: 1.2rem;
}

.background-highlight {
  background-color: #ccc;
  padding: 1rem;
  width: 80%;
  height: auto;
  margin: 0 auto;
  border-radius: 30px;
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .patient-container-border {
    border: 5px solid #b5893f;
    border-radius: 29px;
  }

  .die-container-border {
    border: 5px solid #29abe2;
    border-radius: 29px;
  }
}
/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
}
/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}
