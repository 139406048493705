/* Introduction Page */

.dc-bg-main {
  padding-left: 0;
  padding-right: 0;
}

.col-no-pad .col {
  padding: 0 !important;
}

.dc-bg-main p {
  margin-bottom: 0;
}

.dc-intro-bg-1 {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.dc-intro-bg-1 img {
  width: 100%;
}

@media only screen and (min-width: 400px) {
  .dc-intro-bg-1 img {
    width: 400px;
  }
}

.dc-intro-bg-2 {
  color: white;
}

.dc-intro-sliders {
  margin-top: 20px !important;
}

.dc-button-intro {
  color: white;
  padding: 5px 0px;
  width: 60%;
  background: rgba(196, 196, 196, 0.38);
  border: 0.888889px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
}

.dc-wave-intro {
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 50%;
  right: 0;
  left: 0;
}

.dc-bot-row {
  z-index: 2;
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
  margin-right: 0 !important;
  margin-left: 0 !important;
  background-color: var(--do-primary);
  color: white;
}



@media only screen and (min-width: 490px) {
  .dc-bot-row {
    top: 510px;
  }
}

@media only screen and (min-width: 600px) {
  .dc-bot-row {
    top: 530px;
  }
}

@media only screen and (min-width: 700px) {
  .dc-bot-row {
    top: 560px;
  }
}

@media only screen and (min-width: 860px) {
  .dc-bot-row {
    top: 590px;
  }
}

@media only screen and (min-width: 1000px) {
  .dc-bot-row {
    top: 620px;
  }
}

@media only screen and (min-width: 1500px) {
  .dc-bot-row {
    top: 700px;
  }
}

@media only screen and (min-width: 1600px) {
  .dc-bot-row {
    top: 800px;
  }
}

.dc-contact-row {
  margin-top: 0 !important;
  padding-bottom: 20px !important;
}

.dc-contact-row {
  width: 100%;
}

.dc-contact-row p {
  margin-bottom: 0;
}

.dc-top-contact-row .col {
  display: grid;
  padding-left: 20px;
  padding-right: 20px;
}

.dc-top-contact-row p {
  text-align: center;
}

.dc-top-contact-row .col img,
.dc-top-contact-row .col p {
  justify-self: center;
  align-self: center;
}

.dc-account-text {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  margin-top: 8px;
  text-align: center;
}

.dc-account-text a {
  font-weight: bold;
}

.dc-only-avail {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 15px;
  text-align: center;
}

.dc-rectangle-contact {
  width: 100%;
  margin-top: 5px !important;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--do-primary);
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.dc-contact-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  margin-right: 30px;
}

/* Register Page */

.dc-top-regpage {
  /* margin: 0 !important; */
  margin-top: -2rem !important ;

}

.dc-top-regpage .col {
  position: relative;
  z-index: 1;
  display: grid;
}

.dc-no-pads {
  width: 100vw !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.dc-nopadding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.dc-no-pads .col {
  padding: 0;
}

.dc-no-pads .row {
  margin: 0;
}

.dc-no-pads p {
  margin-bottom: 0;
}

.dc-firstreg-title {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  /* padding-right: 0.5rem; */
  margin-top: 2rem
}

.dc-top-bg1 {
  /* margin-top: 10px; */
  /* width: 180px; */
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  justify-self: center;
  align-self: center;
}

.dc-top-regpage p {
  text-align: center;
  justify-self: center;
  align-self: center;
  margin-bottom: 0;
}

.dc-top-bg2 {
  width: 100%;
  justify-self: center;
  align-self: center;
}

@media only screen and (min-width: 600px) {
  .dc-top-bg2 {
    width: 600px;
  }
}

.dc-reg-bot-row {
  position: fixed;
  bottom: 0;
  z-index: 10;
  margin-right: 0 !important;
  margin-left: 0 !important;
  background: url('../images/bg/doctorImg/intro-page1.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: fit-content;
  padding-top: 100px;
  color: white;
}

.dc-reg-bot-row-otp {
  position: fixed;
  bottom: 0;
  z-index: 10;
  margin-right: 0 !important;
  margin-left: 0 !important;
  background: url('../images/bg/doctorImg/intro-page1.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: fit-content;
  color: white;
}

@media only screen and (min-height: 700px) {
  .main-contact-intro2 {
    margin-top: 15px !important;
    margin-bottom: 10px !important;
  }
}

.dc-register-title {
  text-align: center;
  text-decoration: underline;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.15em;
  margin-bottom: 8px !important;
}

@media only screen and (min-height: 800px) {
 
  .main-contact-intro2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}

.dc-color-white {
  color: white !important;
}

.dc-register-input {
  display: grid;
  justify-self: center;
  align-self: center;
  width: 100%;
  margin-bottom: 10px;
  color: white;
}

.dc-register-row-inputgroup {
  width: 80% !important;
  justify-self: center;
}

.dc-register-row-inputgroup span,
.dc-register-row-inputgroup input {
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
}

.dc-register-input .dc-button-intro {
  justify-self: center;
}

.dc-register-intro {
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 53%;
  right: 0;
  left: 0;
}

.dc-reg-contact-row {
  padding-left: 12px;
  padding-right: 12px;
}

/* otp page */

.dc-otp-row {
  margin-top: 25% !important;
}

.dc-otp-row .col {
  display: grid;
}

.dc-otp-input {
  display: grid;
  justify-self: center;
  align-self: center;
  width: 80% !important;
  margin-bottom: 10px;
}

.dc-otp-input input {
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
}

.dc-resend-otp {
  margin-top: 15px;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  line-height: 15px;
  text-align: center;
}

.dc-otp-input .dc-button-intro {
  justify-self: center;
}

.dc-disclaimer-consent-modal .modal-content {
  background: #ffffff;
  border-radius: 15px;
  padding-top: 18px;
  width: 90%;
}

.dc-disclaimer-consent-modal .modal-dialog-centered {
  justify-content: center;
}

.dc-disclaimer-consent-modal .modal-header {
  justify-content: center;
  font-style: normal;
  font-weight: bold;

  text-align: center;
  border-bottom: none;
}

.dc-disclaimer-consent-modal .modal-footer {
  justify-content: center;
}

.dc-disclaimer-consent-modal-top-body {
  margin-bottom: 35px;
}

.dc-disclaimer-consent-modal-top-body p {
  margin: 0;
  text-align: center;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
}

.dc-disclaimer-consent-modal-top-body p span {
  font-weight: bold;
  color: var(--do-primary);
}

.dc-disclaimer-consent-modal-bot-body {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  line-height: 125%;
}

.dc-disclaimer-consent-modal-bot-body .form-check-input {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}


.dc-disclaimer-consent-modal-bot-body .form-check-input:checked:after {
  content: none !important;
}

.dc-disclaimer-consent-modal-bot-body p {
  margin: 0;
  margin-left: 10px;
  margin-bottom: 10px;
}

.dc-disclaimer-consent-modal-bot-body p span {
  font-weight: bold;
  color: var(--do-primary);
}

.dc-disclaimer-consent-modal .modal-footer button {
  background: #184788;
  border-radius: 15px;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
}

/* first reg page */

.dc-top-wave {
  height: 80px;
  width: 100%;
}

.dc-first-time-reg-title-row,
.dc-top-wave {
  background-color: #f6f6f6;
}

.dc-first-time-reg-title-row {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

.dc-firstreg-wave {
  width: 115px;
  height: 105px;
}
.dc-first-time-reg-title-row h1 {
  text-align: start;
}

.dc-first-time-reg-title-row p {
  text-align: justify;
}

.dc-first-time-reg-body-row {
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);
  padding-top: 5px;
  background-color: white;
}

.dc-id-num {
  justify-content: center;
  align-items: center;
  font-family: Verdana;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 24px;
  margin-bottom: 8px;
}

.id-num-gen {
  color: var(--do-primary);
  font-weight: bold;
}

.dc-id-info {
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  padding: 8px;
  box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.1);

  line-height: 138.53%;
  border-radius: 15px;
  margin-bottom: 25px;
}

.dc-id-info-img {
  margin-right: 15px;
}

.dc-id-info-img img {
  width: 30px;
}

.dc-id-personal-details {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}

.dc-no-shadow {
  box-shadow: none;
}

.dc-id-personal-details,
.dc-id-work-detail {
  padding: 20px;
}

.dc-id-personal-details-img,
.dc-id-work-detail-img {
  margin-right: 15px;
}

.dc-id-personal-details-title,
.dc-id-work-detail-title {
  display: flex;
  align-items: center;
  line-height: 24px;
}

.dc-id-next, .dietitian-id-next {
  width: 40%;
  margin: 0 auto;
  margin-bottom: 0.5rem;
}

.dc-id-next-bot {
  position: fixed;
  left: 50%;
  transform: translateX(-50%)
}

.dc-id-next button {
  width: 100%;
  color: white;
  background: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  border: none;
  padding: 8px;
  line-height: 24px;
  border-radius: 15px;
}

.dc-button-next button {
  width: 100%;
  color: white;
  background: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  border: none;
  padding: 8px;
  line-height: 24px;
  border-radius: 15px;
}

.dietitian-id-next button {
  width: 100%;
  color: white;
  background: linear-gradient(111.59deg, #56b3db -4.37%, #29ABE2 84.87%);
  border: none;
  padding: 8px;
  line-height: 24px;
  border-radius: 15px;
}

/* First Time Personal Details */

.dc-personal-details-form {
  padding-left: 25px;
  padding-right: 25px;
}

.dc-personal-details-form label {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
}

.dc-personal-details-form input,
.dc-personal-details-form select,
.dc-personal-details-form textarea {
  border: 1px solid #184788;
  box-sizing: border-box;
  border-radius: 15px;
  font-weight: bold;
  color: #184788;
}

.dc-personal-next {
  padding: 0 20px; 
}

.dc-personal-next button {
  width: 40%;
  color: white;
  background: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  border: none;
  padding: 8px;
  line-height: 24px;
  border-radius: 15px;
}

option[value=''][disabled] {
  display: none;
}

.dc-work-upload {
  justify-self: center;
  align-self: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 40%;
  background: #184788 !important;
  border: 1px solid #184788 !important;
  box-sizing: border-box;
  border-radius: 15px !important;
  padding: 10px !important;
  line-height: 17px;
}


.dc-work-upload img {
  margin-right: 10px;
}

.form-group-margin-right-3 {
  margin-right: 10px;
}

/* Ftr Success */

.dc-reg-success-header {
  padding-bottom: 40px;
}

.dc-success-img .col {
  display: grid;
}

.dc-success-img {
  margin-bottom: 20px !important;
}

.dc-success-img img {
  justify-self: center;
  align-self: center;
  width: 100px;
  /* margin-bottom: 20px; */
}

.dc-success-img p {
  text-align: center;
  justify-self: center;
  align-self: center;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
}

.dc-success-dlp-code {
  margin-bottom: 10px !important;
}

.dc-success-dlp-code .col {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
}

.dc-success-dlp-code span {
  color: var(--do-primary);
  font-weight: bold;
}

/* HomePage */

.dc-homepage-bg {
  width: 100%;
  height: 100vh;
  background: linear-gradient(108.31deg, #184788 -3.91%, #468ff3 59.53%);
}

.dc-homepage-bg h4 {
  margin-bottom: 0;
}

.dc-homepage-header {
  color: white;
  padding: 25px;
  padding-top: 5px;
}

.dc-patient-search {
  color: black;
  width: 95%;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 15px;
  background: url(../images//icons//doctorIcon/search.png) no-repeat scroll 17px
    17px;
  background-position: center;
  background-position-x: calc(100% - 10px);
  background-color: #fbfbfb;

  line-height: 17px;
  padding: 5px 10px;
}

.dc-homepage-body {
  background-color: #f6f6f6;
  min-height: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.dc-homepage-doctorid {
  margin-top: 15px !important;
  padding: 0px 25px;
}

.dc-homepage-doctorid .col {
  display: grid;
}

.dc-doctor-id {
  width: 200px;
  justify-self: center;
  align-self: center;
  background: #ffffff;
  border: 1.13139px solid rgba(0, 113, 188, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  color: var(--do-primary);
  font-weight: bold;
  line-height: 29px;
  text-align: center;
  font-size: 1.5rem;
  padding: 8px;
}

/* .dc-homepage-recent {
  margin-top: 25px !important;
  padding-left: 20px;
}

.dc-recent-text {
  color: #c4c4c4;
} */

.dc-homepage-summary {
  color: var(--do-primary);
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  line-height: 17px;
}

.dc-homepage-summary .col {
  background-color: white;
  border: 1.13139px solid rgba(0, 113, 188, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 10px;
}

.dc-homepage-summary-first {
  margin-bottom: 10px !important;
}

.dc-homepage-summary-first .col:nth-of-type(1),
.dc-homepage-summary-sec .col:nth-of-type(1) {
  margin-right: 10px;
}

.dc-homepage-patientdb-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dc-see-all {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  color: #0071bc;
  line-height: 17px;
}

.dc-patient {
  margin-top: 15px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  padding: 10px;
  background-color: white;
}

.dc-patient:nth-of-type(1) {
  margin-top: 5px;
}

.dc-patient-img {
  /* background-color: gray; */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.dc-patient-img img {
  width: 100%;
  object-fit: contain;
}

.dc-patient-details {
  display: grid;
}

.dc-patient-details h5 {
  margin: 0;
}

.dc-patient-details p {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
}

.dc-patient-details h5,
.dc-patient-details p {
  align-self: center;
}

.dc-main-add-patient {
  width: 100%;
  position: fixed;
  bottom: 1%;
  right: 2%;
}

.dc-main-add-patient .col,
.dc-main-add-patient {
  background: transparent;
}

.dc-main-add-patient .col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dc-main-add-patient-img {
  width: 68px;
  height: 68px;
}

/* Patient Details */

.dc-patient-details-bg {
  min-height: 100vh;
  background-color: #f6f6f6;
}

.dc-patient-details-body {
  padding: 10px;
  padding-top: 5px;
}

.dc-patient-details-box {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  padding: 20px;
}

.dc-patient-details-box p,
.dc-patient-details-box h5 {
  margin-bottom: 5px;
}

.dc-patient-details-box-img {
  background-color: gray;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.dc-patient-details-box-img-die {
  background-color: gray;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.dc-patient-details-buttons {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  padding: 20px;
  margin-bottom: 15px;
}

.dc-patient-details-buttons-img {
  background-color: #f4f4f4;
  border-radius: 50%;
  width: 28.33px;
  height: 28.33px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dc-patient-details-buttons-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dc-contact-patient-details {
  color: var(--do-primary);
}

.dc-patient-details-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;

  line-height: 16px;
}

.dc-patient-details-nav button {
  background: #184788;
  border: 1px solid #184788 !important;
  border-radius: 5px;
  border: none;
  color: white;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.dc-patient-details-nav button:first-of-type {
  margin-right: 5px;
}

.dc-patient-details-nav button:last-of-type {
  margin-left: 5px;
}

.dc-patient-details-info1 {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  align-items: flex-start;
  padding-top: 15px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;
}

.dc-patient-details-info1 p {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
}

.dc-patient-details-visit2-body {
  background: #cce2ff;
  border: 1px solid rgba(93, 149, 227, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 15px;
}

.dc-patient-details-visit2-body .row {
  margin-bottom: 25px;
}

.dc-patient-details-visit2-body .row:last-of-type {
  margin-bottom: 0;
}

.dc-patient-details-visit2-body .col {
  display: flex;
}

.dc-patient-details-blue-box-visit2 {
  background: #184788;
  border: 1px solid rgba(93, 149, 227, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}

.dc-patient-details-blue-box-visit2 img {
  width: 41px;
  height: 35.6px;
}

.dc-patient-details-visit2-body-text {
  display: grid;
  margin-left: 10px;
}

.dc-patient-details-visit2-body-text .body-text-heading {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;

  color: var(--do-primary);
  margin-bottom: 5px;
}

.dc-patient-details-visit2-body-text .body-text-content {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;

  text-align: start;
}

/* Dietitian Report Page */

.dc-dietitian-top-body {
  background: #e9f2f7;
  border-radius: 15px;
  padding: 25px;
}

.dc-dietitian-top-body-report p {
  text-align: justify;
}

.dc-dietitian-read-more {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 5px; */
  color: var(--do-primary);
}

.dc-dietitian-read-more button {
  background: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  border-radius: 7px;
  color: white;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;

  line-height: 12px;
  padding: 7px 20px;
  border: none;
}

.dc-dietitian-note {
  margin-top: 10px !important;
  padding: 15px;
}

.dc-dietitian-appointment {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 10px;
}

.dc-download-pdf {
  display: grid;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

.dc-download-pdf a {
  justify-self: center;
  align-self: center;
  border-radius: 15px;
  border: none;
  color: white !important;
  font-style: normal !important;
  width: 100%;
  padding: 15px;
  background: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
}

.dc-download-pdf button {
  justify-self: center;
  align-self: center;
  border-radius: 15px;
  border: none;
  color: white !important;
  font-style: normal !important;
  width: 100%;
  padding: 15px;
  background: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
}

.dc_module_box {
  background: #cce2ff !important;
}

.dc-optional-container {
  background-color: #dbe8fa !important;
  padding: 8px;
}

.dc-optional-container .accordion-body {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.dc-optional-container .accordion-button {
  background: #dbe8fa !important;
}

.dc-optional-container input {
  border: 1px solid #6f8cb5 !important;
}

.dc-optional-container .pt-form-select {
  color: var(--do-primary) !important;
}

.dc-choose-module-comment {
  border: 1px solid #6f8cb5;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 1px;
  padding-left: 10px;
  padding-right: 50px;
  padding-bottom: 5px;
  outline-color: var(--do-primary);
}

.dc-comment-text-area {
  background: rgba(199, 221, 245, 0.5);
  border-radius: 5px;
  width: 100%;
  border: none;
  outline-style: none !important;
  padding: 5px;
}

.dc-dietitian-appointment-top {
  background: #ffffff;
  border: 1px solid rgba(93, 149, 227, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 8px 15px;
  display: flex;
}

.dc-dietitian-appointment-top-date {
  background: rgba(199, 221, 245, 0.5);
  border-radius: 5px;
  padding: 5px 15px;
  color: var(--do-primary);
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  margin-right: 15px;
}


.dc-dietitian-appointment-top-desc p:nth-of-type(1) {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
}

.dc-dietitian-appointment-top-desc p:nth-of-type(2) {
  font-family: Verdana;
  font-style: normal;

  color: var(--do-primary);
}

.dc-dietitian-footer {
  position: fixed;
  bottom: 0%;
  /* background: white; */
  padding: 5px 20px;
  width: 100%;
}

.dc-dietitian-enter-notes-button {
  background: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  border-radius: 15px;
  border: none;
  color: white;
  width: 100%;
  padding: 15px;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
}

/* User Profile / Patient Info */

.dc-profile-header .col {
  display: grid;
}

.dc-profile-header h4 {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;

  line-height: 24px;
  text-align: center;
  color: var(--do-primary);
  margin-bottom: 20px;
}

.dc-profile-header h4,
.dc-profile-header .dc-profile-header-img {
  justify-self: center;
  align-self: center;
}

.dc-profile-header-img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

.dc-profile-header-img img {
  object-fit: contain;
}

.dc-profile-body {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.dc-profile-text-field {
  width: 100%;
  margin-bottom: 20px;
}

.dc-profile-label {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
}

.dc-profile-input {
  width: 100%;
}

.dc-profile-text-field input {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
}

.dc-profile-footer .col {
  display: flex;
  justify-content: center;
}

.dc-profile-footer-button {
  width: 90%;
}

/* med prof */

.dc-profile-text-field-body {
  display: flex;
}

.dc-profile-text-field-body-div {
  padding-right: 25px;
}

/* Register new patient */

.dc-patient-reg-elig-checkbox {
  background: #dbe8fa;
  border-radius: 29px;
  margin-left: 20px !important;
  margin-right: 20px !important;
  padding: 15px;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
}

.dc-patient-reg-elig-checkbox .form-check-input {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-left: 10px;
}

.dc-patient-reg-elig-checkbox .d-flex {
  margin-bottom: 8px;
}

#formPatientDiabetes,
.selectOpen option,
#formPatientOptional {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;

  color: var(--do-primary);
}

.selectOpen option {
  margin-bottom: 5px;
  padding-bottom: 3px;
  border-bottom: 1px solid #c4c4c4;
}

.dc-patient-code-checkbox {
  border-radius: 19px;
}

.dc-button-disable button {
  background: rgba(86, 81, 81, 0.48);
}

.dc-modal-success-1 .modal-content {
  background: #ffffff;
  border-radius: 12px;
}

.dc-modal-success-1 .modal-body img {
  width: 116px;
  height: 116px;
  margin-bottom: 15px;
}

.dc-modal-success-1 .modal-body p {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;

  line-height: 165.5%;
  text-align: center;
}

.dc-modal-success-1 .modal-footer {
  justify-content: center;
  align-items: center;
}

.dc-modal-success-1 .modal-footer button {
  background: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  border-radius: 15px;
}

.dc-modal-success-1 .modal-dialog {
  margin: 0 auto;
  margin-left: 2rem;
  margin-right: 2rem;
}

.dc-white-button-clicked {
  background: #ffffff !important;
  border: 1px solid #184788 !important;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  color: #184788 !important;
}

.dc-info-module {
  width: 90%;
  margin-left: 5%;
  margin-bottom: 20px;
}

.dc-info-module p {
  margin-bottom: 0;
}

/* Lifestyle progress */

.dc-text-center-heading-blue {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  color: var(--do-primary);
}

.dc-questionaire-check-box {
  background: #ffffff;
  border: 1px solid rgba(93, 149, 227, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  justify-content: start;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: 100%;
  padding: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  margin-bottom: 10px;
}

.dc-questionaire-check-box p {
  margin: 0;
}

.dc-questionaire-img {
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
}

.dc-questionaire-img img {
  width: 100%;
}

.dc-new-comment-button {
  margin-left: 0 !important;
}

.dc-questionaire-heading {
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;

  margin-bottom: 5px !important;
}

.dc-questionaire-body {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;

  color: #29abe2;
}

.quest-checked {
  background: #184788;
  border: 1px solid rgba(93, 149, 227, 0.5);
}

.quest-checked .dc-questionaire-heading {
  color: white;
}

.dc-add-comment-preview {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
}

.dc-add-comment-preview button {
  background: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  border-radius: 7px;
  border: none;
  color: white;
  padding: 8px 25px;
  margin-top: 10px;
}

.dc-form-title {
  margin-left: 6%;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
}

.dc-margin-centering {
  margin-left: 5%;
  width: 90%;
}

.dc-dietitian-appointment p {
  margin-bottom: 0;
}

.dc-dietitian-appointment {
  justify-self: center;
  align-self: center;
}

.dc-comment-grid {
  margin: 0px 10px;
  padding: 0 !important;
}

.dc-no-m {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.dc-add-comment-row {
  margin-top: 10px !important;
}

.dc-add-comment-row .col {
  justify-content: flex-start;
  align-items: center;
}

.dc-add-comment-row img {
  margin-left: 5px;
  margin-right: 10px;
}

.dc-add-comment-row p {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  color: var(--do-primary);
  margin-bottom: 0;
}

/* NEW DOCTOR INTRO */

.dc-intro-min-height {
  min-height: fit-content !important;
  padding: 0 !important;
  width: 100vw !important;
}


.dc-splide-intro .splide__pagination button {
  width: 7px;
  height: 7px;
}


.dc-splide-intro .splide__list {
  height: 100vh;
}

.dc-curved-intro {
  background-image: url(../images/bg/doctorImg/intro-page1.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  width: 100vw;
  height: fit-content;
  padding-top: 23%;
  /* padding-bottom: 100%; */
  position: fixed;
  bottom: 0;
}

.dc-curved-intro-2 {
  background-image: url(../images/bg/doctorImg/intro-page2.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  width: 100vw;
  padding-top: 30%;
  position: absolute;
  top: 35%;
}

.dc-curved-intro-3 {
  background-image: url(../images/bg/doctorImg/intro-page3.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  width: 100%;
  height: fit-content;
  padding-top: 10%;
  padding-left: 10px;
  padding-right: 10px;
  position: fixed;
  bottom: 0;
}

.dc-curved-intro-4 {
  background-image: url(../images/bg/doctorImg/intro-page4.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  height: fit-content;
  width: 100vw;
  padding-top: 50%;
  padding-bottom: 100%;
  position: fixed;
  top: 30%;
  display: grid;
}

.dc-intro-no-m-p.col {
  padding: 0;
  margin: 0;
}

.dc-top-img-intro {
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-bottom: 0;
  padding-top: 0;
}

.dc-top-img-intro img {
  width: 100%;
}

.dc-top-img-intro-3 {
  /* margin-top: 15px; */
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 170px; */
}

.dc-top-img-intro-3 img {
  width: 400px !important;
  /* margin-top: -50px; */
}

.dc-top-img-intro-7 {
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dc-top-img-intro-7 img {
  width: 330px;
}

.dc-font-intro {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;

  line-height: 24px;
  margin-bottom: 0;
  width: 268px;
}

.dc-bot-contact-row {
  width: 100%;
  margin: 0 !important;
}

.dc-bot-img-intro {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dc-bot-img-intro img {
  position: relative;
  z-index: -9999;
}



.dc-curved-body-text .col {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
}

.dc-curved-contact {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  top: 70%;
  background-color: #184788;
  padding-bottom: 20px;
  margin: 0 !important;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}

.dc-curved-contact .col {
  padding: 0 !important;
}

.dc-curved-text-4 {
  margin-top: 0px !important;
  text-align: center;
  justify-self: center;
  align-self: center;
  margin-bottom: 5px;
}

.dc-curved-text-4 p {
  margin-bottom: 0;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;

  line-height: 24px;
  text-align: center;
}

.dc-curved-button-4 {
  justify-self: center;
  align-self: start;
}

.dc-curved-button-4 .col {
  display: flex;
  justify-content: center;
}

.dc-curved-button-4 button {
  background: rgba(196, 196, 196, 0.38);
  border: 0.888889px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 7px 15px;
  color: white;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
}

.dc-intro-button-4 {
  background: rgba(196, 196, 196, 0.38);
  border: 0.888889px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 7px 15px;
  color: white;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 13px;
}



.dc-input-error-line {
  border: solid 2.5px rgb(244, 67, 54) !important;
}

.dc-error {
  margin-top: 5px;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  color: rgb(244, 67, 54);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dc-error h6 {
  margin-bottom: 0;
}

.dc-buttons-o-margin {
  margin-bottom: 4rem !important;
}

.dc-box-ftj {
  background: #bddaed !important;
}

.dc-homepage-body-summary-data {
  justify-self: center;
  align-self: center;
  margin-top: 5px;
  margin-bottom: 8px;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
  text-align: center;
  color: #184788;
}

.dc-patient-db-header header {
  background: transparent;
}

.dc-no-marg {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.dc-no-marg p {
  margin-bottom: 0;
}

.dc-margin-alot {
  /* margin-bottom: 100%; */
  /* padding-bottom: 100%; */
  margin: 0 !important;
}

.dt-homepage-bg {
  height: 100vh;
  background-color: #29abe2;
}

.dc-addcomment-patient-button {
  background: #184788;
  border: 1px solid rgba(93, 149, 227, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 100%;
  /* margin-left: 5vw; */
  color: white;
  margin-bottom: 20px;
  padding: 20px;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  line-height: 13px;
}

.dc-addcomment-patient-button p {
  margin-bottom: 0;
}

.dc-addcomment-patient-button .col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dc-addcomment-patient-button-img {
  margin-right: 15px;
}

.dc-addcomment-patient-button-img img {
  width: 31px;
}

.dc-addcomment-patient-button-text {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.2rem;
}

.dc-height-input {
  border-right: none !important;
}

.dc-span-input {
  background: white !important;
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  border: 1px solid #184788 !important;
  border-left: none !important;
}

.dt-patient-search {
  color: black;
  width: 100%;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 15px;
  background: url(../images//icons//doctorIcon/search.png) no-repeat scroll 17px
    17px;
  background-position: center;
  background-position-x: calc(100% - 10px);
  background-color: #fbfbfb;

  line-height: 17px;
  padding: 5px 10px;
}

.dc-back-button {
  background: white !important;
  color: #184788 !important;
  padding: 6px !important;
  border: 2px solid #184788 !important;
}

.dt-homepage-body {
  background-color: #f6f6f6;
  min-height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.dt-dietitian-read-more button {
  background: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  border-radius: 7px;
  color: white;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;

  line-height: 12px;
  padding: 7px 20px;
  border: none;
}

.dt-dietitian-note {
  margin-top: 10px !important;
}

.dc-editable-dropdown {
  border: 1px solid #184788;
  box-sizing: border-box;
  border-radius: 15px;
  font-weight: bold;
  color: #184788;
}

.dc-editable-dropdown .css-1s2u09g-control,
.dc-editable-dropdown .css-1pahdxg-control {
  border: none !important;
  border-radius: 15px !important;
}

.dc-img-preview {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  object-fit: cover;
}

.dc-img-preview img {
  width: 50%;
}

.dc-box-policy-main {
  text-align: justify;
  margin: 0 !important;
  margin-top: 20px !important;
  margin-left: 5px !important;
  width: 97% !important;
}

.dc-policy-body {
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: justify;
}

.dc-policy-body span {
  margin-right: 15px;
}

.dc-policy-body p {
  margin-bottom: 15px;
}

.dc-med-titles {
  text-align: center;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 5px;
}

/* NEW SPLIDE */

.main-splide-height {
  height: 100vh;
  width: 100vw;
}

.intro-main-logo {
  position: relative;
  z-index: 1;
  width: 200px;
  justify-self: center;
  align-self: center;
}

.intro-main-logo6 {
  width: 200px;
  margin-bottom: -5rem;
}

.intro-main-logo2 {
  position: relative;
  z-index: 1;
  width: 210px;
  justify-self: center;
  align-self: center;
  /* padding-left: 4.5rem;
  padding-right: 4.5rem; */
}

.intro-main-logo3 {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.main-overlap-top2 {
  /* margin-top: -5px !important; */
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.main-curved-background1 {
  background-image: url('../images/bg/doctorImg/intro-page1.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.main-curved-background-reg {
  position: relative;
  z-index: 5;
  background-image: url('../images/bg/doctorImg/intro-page1.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: fit-content;
}

.main-curved-background2 {
  position: relative;
  z-index: 5;
  background-image: url('../images/bg/doctorImg/intro-page3.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.main-intro-content {
  margin-top: 36%;
  text-align: center;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 24px;

  margin-bottom: 25px;
}

.main-intro-content2 {
  margin-top: 20%;
  text-align: center;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 24px;

  margin-bottom: 25px;
}

.main-intro-content2 p {
  margin-bottom: 30px;
}

.main-contact-intro {
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 10;
}

.main-contact-intro2 {
  margin-top: 5px;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
}

.main-contact-intro p,
.main-contact-intro2 p {
  margin: 0;
}

.main-contact-address {
  color: white;
  display: grid;
}

.main-contact-address img {
  margin-bottom: 5px;
}

.main-contact-address img,
.main-contact-address p {
  justify-self: center;
  align-self: center;
}

.main-contact-address p {
  margin: 0;
}

.main-navigation {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.main-nav-pagination ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.main-nav-arrow-left {
  transform: rotate(180deg);
}

.active-page {
  background: #ffffff !important;
  transform: scale(1.2);
}
.main-nav-arrow-right{
  padding-right: 1rem;
}

.main-splide-arrow-pos .splide__arrow {
  display: none;
  top: 65%;
}

.main-splide-pagination-pos .splide__pagination {
  display: none;
  top: 31%;
}

.intro-main-reg-1 {
  width: 100vw;
}

.main-overlap-top {
  margin-top: -20% !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.main-overlap-top2 {
  margin-top: -10% !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.dc-reg-content {
  margin-top: 25%;
}

.main-contact-intro-reg p {
  margin: 0;
}

/* .main-contact-intro-reg {
  padding-bottom: 100%;
} */

.empty-padding-dc {
  background: #184788;
  width: 100%;
  position: absolute;
  /* padding: 10px; */
  bottom: 0;
  height: 30%;
}

.dc-top-img-intro2 {
  justify-content: center;
  align-items: center;
}

.dc-top-img-intro2 img {
  width: 280px;
}

.dc-top-img-intro-4 {
  justify-content: center;
  align-items: center;
  /* margin-top: -20px; */
}

.dc-top-img-intro-4 img {
  width: 230px;
}

@media only screen and (min-height: 600px) {
  .dc-curved-intro {
    padding-top: 28%;
    padding-bottom: 50px;
  }

  .dc-curved-intro-3 {
    padding-bottom: 50px;
  }

  .dc-top-img-intro-7 img {
    width: 350px;
  }

  .dc-nav-intro {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

@media only screen and (min-height: 700px) {
  .dc-curved-intro {
    padding-bottom: 60px;
  }

  .dc-curved-intro-3 {
    padding-bottom: 60px;
  }

  .dc-top-img-intro-7 img {
    width: 100%;
  }

  .dc-nav-intro {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-height: 800px) {
  .dc-curved-intro {
    padding-bottom: 90px;
  }

  .dc-curved-intro-3 {
    padding-bottom: 90px;
  }

  .dc-top-img-intro-7 img {
    width: 100%;
  }

  .dc-nav-intro {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .dc-specific-contact-row {
    margin-top: 40px !important;
  }

  .dc-intro-button-4 {
    margin-top: 10px;
  }
}

.modal-dialog-centered {
  justify-content: center;
}

.dc-helpdesk-row {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.dc-helpdesk-title {
  font-weight: bold;
}

.dc-helpdesk-text {
  text-align: justify;
  font-size: 1.2em;
  line-height: 1.2rem;
}

.dc-helpdesk-info {
  font-size: 0.9em;
  margin-top: 15px;
  padding-bottom: 10px;
}

.dc-helpdesk-img {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dc-helpdesk-img img {
  width: 130%;
}

.dc-helpdesk-input-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dc-helpdesk-input-row .col {
  display: grid;
}

.dc-helpdesk-input-label {
  font-weight: bold;
  padding-left: 15px;
  width: 98%;
  justify-self: center;
}

.dc-helpdesk-input-select {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  width: 98%;
  justify-self: center;
  margin-bottom: 35px;
}

.dc-helpdesk-disabled-input {
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  color: #888888;
}

.dc-helpdesk-button {
  width: 100%;
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.dc-helpdesk-button button {
  background: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  border-radius: 15px;
  border: none;
  color: white;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Verdana;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
}

.dc-fitness-check input {
  border: 1px solid #6f8cb5 !important;
  color: #184788 !important;
}

.dc-fitness-check-text-area {
  background: white !important;
  border: 1px solid #6f8cb5 !important;
  color: #184788 !important;
  border-radius: 15px !important;
}

.dc-fitness-check-button button {
  width: 100%;
  background: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  border-radius: 15px;
  border: none;
  color: white;
  font-size: 1.5em;
  padding: 5px;
}

.dc-set-appointment-modal .modal-content {
  border-radius: 12px;
}

.dc-set-appointment-modal .modal-footer button {
  border: none;
  width: 100%;
}

.dc-set-appointment-modal-calendar {
  border-radius: 15px !important;
  border: 1px solid #184788 !important;
}

.dc-upload-file-container label {
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}

.dc-upload-file-container button {
  width: 100%;
  color: white;
  background: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  border: none;
  font-size: 1.2em;
  padding: 15px;
  line-height: 24px;
  border-radius: 15px;
}

.dc-review-title-comment {
  width: 100%;
  padding: 3px;
  padding-left: 15px;
  border: 1px solid #6f8cb5;
  transition: border 0.35s ease-in-out;
  border-radius: 15px;
  color: #184788;
  font-weight: bold;
}

.dc-review-title-comment:focus {
  border: 1px solid #184788 !important;
}

.noheader-pad header {
  padding-bottom: 0 !important;
  font-size: 18px;
}

/* desktop view */
/* Custom, iPhone Retina */
/* @media only screen and (min-width : 320px) {
        
} */

/* Extra Small Devices, Phones */
/* @media only screen and (min-width : 480px) {

} */

.doctor-home-page-header {
  width: 100%;
  background: linear-gradient(108.31deg, #184788 -3.91%, #468ff3 59.53%);
}

.dc-patient-card {
  margin-top: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 10px;
  width: 100%;
}

.dc-main-add-patient-btn {
  position: absolute;
  bottom: 0%;
  right: 9%;
}

.dc-patient-details-box-desk {
  display: none;
}

.header-navbar-div {
  position: relative;
  width: 100%;
  z-index: 99999999;
  background: #fff;
}
.custom-nav-desktop {
  display: none;
}
.column-divider {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.container-padding-narrow-note {
  width: 70%;
  margin: 0 auto;
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .dietitian-id-next,
  .dc-id-next {
    width: 80%;
    margin-bottom: 0.5rem;
  }



  .dc-work-upload{
    width: 100%;
  }
  .dc-personal-next button{
    width: 100%;
  }
}


@media only screen and (min-width: 768px) {
  .custom-nav-desktop {
    display: block;
  }

  .dc-reg-bot-row-otp {
    position: fixed;
    bottom: 0;
    z-index: 10;
    margin-right: 0 !important;
    margin-left: 0 !important;
    background: url('../images/bg/dr-blue-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: fit-content;
  }

 
  .dc-reg-bot-row {
    height: 36rem;
  }

  .dr-container-border {
    border: 5px solid #184788;
    border-radius: 29px;
  }

  .dr-container-border-pad {
    padding: 2rem 4rem;
  }
  .dietitian-id-next,
  .dc-id-next {
    width: 40%;
    margin: 0 auto;
    margin-bottom: 0.5rem;
  }



  .dc-top-wave {
    display: none;
  }
  .dc-first-time-reg-title-row {
    background-color: #fff;
  }

  .dc-first-time-reg-body-row {
    box-shadow: none;
  }
  .dc-id-next-bot {
    position: relative !important;
    margin-top: 2rem;
  }

  .dc-patient-details-box {
    display: none;
  }

  .dc-patient-details-body {
    padding: 0;
  }

  .dc-patient-details-box-desk {
    background: #ffffff;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px; */
    display: flex;
    padding: 20px;
  }

  .dc-patient-details-box-img {
    background-color: gray;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    margin-right: 15px;
  }

  .desktop-dr-bg-color {
    background: linear-gradient(101.19deg, #184788 -15.95%, #468ff3 54.64%);
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  .dr-main-content {
    flex: 1 1 auto;
    background-color: #fff;
  }
  .desk-patient-details-content-w-50 {
    width: 50%;
    margin: 0 auto;
  }

  .dc-patient-details-buttons {
    padding: 40px 20px;
  }

  .container-padding-narrow {
    width: 70% !important;
    margin: 0 auto;
  }

  .desk-header-div {
    width: 100%;
    background-color: #c4c4c4;
    padding: 2rem;
  }
  .dr-patient-container-border {
    border: 1px solid #b5893f;
    border-radius: 5px;
  }

  .search-bar-width {
    width: 50%;
  }
  .dc-patient-card {
    width: 49% !important;
  }

  .column-divider {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
 

  .dc-reg-bot-row-otp {
    position: fixed;
    bottom: 0;
    z-index: 10;
    margin-right: 0 !important;
    margin-left: 0 !important;
    background: url('../images/bg/dr-blue-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: fit-content;
  }

  .dc-id-num {
    font-size: 1.4rem;
    padding: 1rem 0;
  }

  .dc-otp-row {
    margin-top: 16% !important;
  }

  .intro-main-logo {
    position: relative;
    z-index: 1;
    width: 220px;
    justify-self: center;
    align-self: center;
  }

  .intro-main-logo2 {
    position: relative;
    z-index: 1;
    width: 230px;
    justify-self: center;
    align-self: center;
  }

  .dc-bot-contact-row {
    width: 35%;
    margin: 0 auto !important;
  }
  .dc-register-input {
    width: 40%;
  }

  /* register doctor page  */
  .dc-reg-bot-row {
    height: 36rem;
  }
  .register-box-content {
    margin-top: 9rem !important;
  }

  .dc-otp-input {
    width: 35% !important;
  }

  .dc-doctor-id {
    width: 100%;
  }
  .doctor-id-holder {
    width: 40%;
    min-width: 300px;
  }

  .dr-container-border {
    border: 5px solid #184788;
    border-radius: 29px;
  }

  .dr-container-border-pad {
    padding: 2rem 4rem;
  }
  .dietitian-id-next,
  .dc-id-next {
    width: 40%;
    margin: 0 auto;
    margin-bottom: 0.5rem;
  }
  .dc-top-wave {
    display: none;
  }
  .dc-first-time-reg-title-row {
    background-color: #fff;
    padding: 0;
  }
  .dc-first-time-reg-body-row {
    box-shadow: none;
  }
  /* .container-padding px */
  .container-padding {
    padding: 0 2rem;
  }

  .dr-reg-success {
    width: 50% !important;
  }

  .desk-bigger-text {
    font-size: 1.2rem;
    padding: 1rem;
  }
  .dc-id-next-bot {
    position: relative !important;
    margin-top: 2rem;
  }

  .dc-id-next-dr {
    width: 100%;
  }

  .dc-patient-details-box {
    display: none;
  }

  .dc-patient-details-body {
    padding: 0;
  }

  .desktop-dr-bg-color {
    background: linear-gradient(101.19deg, #184788 -15.95%, #468ff3 54.64%);
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  .dr-main-content {
    flex: 1 1 auto;
    background-color: #fff;
  }
  .desk-patient-details-content-w-50 {
    width: 50%;
    margin: 0 auto;
  }

  .dc-patient-details-buttons {
    padding: 40px 20px;
  }

  .dc-patient-details-nav button {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .dc-reg-bot-row {
    position: fixed;
    bottom: 0;
    z-index: 10;
    margin-right: 0 !important;
    margin-left: 0 !important;
    background: url('../images/bg/dr-blue-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: fit-content;
    padding-top: 100px;
    color: white;

  }


  .dc-reg-bot-row-otp {
    position: fixed;
    bottom: 0;
    z-index: 10;
    margin-right: 0 !important;
    margin-left: 0 !important;
    background: url('../images/bg/dr-blue-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: fit-content;
  }

  .dc-otp-row {
    margin-top: 16% !important;
  }

  .dc-bot-contact-row {
    width: 35%;
    margin: 0 auto !important;
  }
  .dc-register-input {
    width: 40%;
  }
  .dc-reg-bot-row {
    height: 36rem;
  }
  .register-box-content {
    margin-top: 9rem !important;
  }
  .dc-otp-input {
    width: 35% !important;
  }
  .dr-container-border {
    border: 5px solid #184788;
    border-radius: 29px;
  }
  .dr-container-border-pad {
    padding: 2rem 4rem;
  }

  .dc-patient-details-box {
    display: none;
  }

  .dc-patient-details-body {
    padding: 0;
  }

  .desktop-dr-bg-color {
    background: linear-gradient(101.19deg, #184788 -15.95%, #468ff3 54.64%);
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  .dr-main-content {
    flex: 1 1 auto;
    background-color: #fff;
  }
  .desk-patient-details-content-w-50 {
    width: 50%;
    margin: 0 auto;
  }

  .dc-patient-details-buttons {
    padding: 50px 20px;
  }

  .dc-patient-details-nav button {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
